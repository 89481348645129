* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #FFFFFF;
  font-family: 'Fedra', sans-serif;
  font-style: normal;
  font-size: 16px;
  //font-size: 1.369863vw;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, textarea, select, button {
  font-family: 'Fedra', sans-serif;
  border: none;
  outline: none;
  appearance: none;
}

a {
  text-decoration: none;
}
