@font-face {
    font-family: 'Fedra';
    src: url('./FedraSansTogg-Bold.woff2') format('woff2'),
         url('./FedraSansTogg-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra';
    src: url('./FedraSansTogg-BoldItalic.woff2') format('woff2'),
         url('./FedraSansTogg-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra';
    src: url('./FedraSansTogg-Book.woff2') format('woff2'),
         url('./FedraSansTogg-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra';
    src: url('./FedraSansTogg-BookItalic.woff2') format('woff2'),
         url('./FedraSansTogg-BookItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra';
    src: url('./FedraSansTogg-Demi.woff2') format('woff2'),
         url('./FedraSansTogg-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra';
    src: url('./FedraSansTogg-DemiItalic.woff2') format('woff2'),
         url('./FedraSansTogg-DemiItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra';
    src: url('./FedraSansTogg-Light.woff2') format('woff2'),
         url('./FedraSansTogg-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra';
    src: url('./FedraSansTogg-LightItalic.woff2') format('woff2'),
         url('./FedraSansTogg-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra';
    src: url('./FedraSansTogg-Medium.woff2') format('woff2'),
         url('./FedraSansTogg-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fedra';
    src: url('./FedraSansTogg-MediumItalic.woff2') format('woff2'),
         url('./FedraSansTogg-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
